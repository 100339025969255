import React from "react";
import { useState, useEffect } from 'react' ;
import { getUsersLean } from '../services/api' ; 
import { useNavigate } from 'react-router-dom' ;
import { AuthContext } from "../App";
import {Paper, Autocomplete , TextField, Button, Stack} from "@mui/material";
import { limitString } from "./EMCUtils";
import { WaitForData } from "./EMCRenderUtils";


export const AdminLogin = () => {
  const { dispatch } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const initialState = {
    user : "",
    password: "",
    active:false
  };
const [data, setData] = useState(initialState);
const [open, setOpen] = useState(false);
const [users, setUsers]= useState([]);

useEffect(() => {
    getUsersLean()
      .then (res => addLabelsForUsers(res))
      .then (res=> { setUsers(res) ; setOpen(false)});
},[]);
const handleInputChange = (e,v,r) => {
    setData({
      ...data,
      user: v.id ,
      pic: v.pic ,
      active: v.active,
      password : 'emc2021'
    });
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (data.password == 'emc2021')
    {
        setData({
        ...data,
        isSubmitting: false,
        errorMessage: null
        });
        console.log( data.active);
        dispatch({ type: "LOGIN", payload: {user:data.user, pic:data.pic, token:"elakjwerlkhwer" } }) ;
        return data.active ? navigate("/recent") : navigate("/profile") ;
    }
    else {
        setOpen(true) ;
    }
  }

const addLabelsForUsers = (users) => {
	return users.map( u => ( { ...u, key:u.id, label: limitString( u.firstname+' '+u.lastname , 15 )}));
}

return (
    <Paper>
    <Stack spacing={2}  sx={{p:10}} direction="column" justifyContent="center" alignItems="center" >
      <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={users}
          sx={{ width: 300 }}
          onChange={(e,v,r) => handleInputChange (e,v,r)}
          renderInput={(params) => <TextField {...params} label="Select User" />}
      />
      <Button variant="contained" onClick={(e)=>handleFormSubmit(e)}>Login</Button>
    </Stack> 
      <WaitForData show={open}/>
    </Paper>
   );

};

export default AdminLogin;
