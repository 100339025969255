import React from 'react';
import {useState, useEffect} from 'react' ;
import {Container, Divider, Paper } from '@mui/material';
import {WaitForData, RenderMembers, RenderDashboardHeader } from './EMCRenderUtils';
import { getUsersLean } from '../services/api';

const Members = () => {
    const [ready,setReady]  = useState(false);
    const [data,setData] = useState([]);

    useEffect(() => {
      getUsersLean()
      .then (res=> { setData(res) ; setReady(true)});
    },[]);

    const activeMembers = data.filter( d=> d.active==true )
    const inactiveMembers = data.filter (d=> d.active==false )
    console.log(activeMembers);
    return (
      <Paper sx={{p:0.1}}>
      <RenderDashboardHeader maintext="EMC Members"/> 
      <RenderMembers members={activeMembers} color="success"/>
      <RenderMembers members={inactiveMembers} color="warning"/>
      <WaitForData show={!ready} />
      </Paper>
    );
}

export default Members;
