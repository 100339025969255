import React from 'react';
import {blueGrey} from '@mui/material/colors'
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import {Card,Backdrop,CircularProgress, CardActions,IconButton, CardMedia,Link, Box, CardHeader, CardContent,Paper,Alert,Snackbar, Grid, Slider,Icon, ToggleButton, ToggleButtonGroup, FormGroup, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Chip,  Divider,  Badge, Stack, Typography,TextField, Checkbox, StyledEngineProvider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DirectionsRunOutlined, DirectionsWalkOutlined, PedalBikeOutlined, PoolOutlined, FitnessCenterOutlined, Label, DirectionsRun, DirectionsBikeOutlined} from '@mui/icons-material';
import { useState } from "react";
import { savePreferences } from '../services/api';
import { GetWeekNumber, limitString } from './EMCUtils';
import { ReactComponent as Spring } from "../img/spring-new.svg";
import { ReactComponent as Summer } from "../img/summer-new.svg";
import { ReactComponent as Autumn } from "../img/autumn-new.svg";
import { ReactComponent as Winter } from "../img/winter-new.svg";
import Strava_Logo from '../img/strava_symbol_orange.png'
import SvgIcon from "@mui/material/SvgIcon";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/Close';
import {Favorite} from "@mui/icons-material";
import {EventCard} from './EventCard';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useNavigate } from 'react-router-dom' ;

  /*import SportsScoreIcon from '@mui/icons-material/SportsScore';
  * Renders a badge with associated points. Takes 3 parameters 
  * icon - Unicode text representing the icon
  * variant - Typography variant that determines the size of the icons rendered
  * points - Points to display on the badge
  */
  const BadgeWithPoints = ({icon, variant, points,  }) => {
        if (points == 0) {
            return <></>
        }
        return(
            <Badge badgeContent={points} color="primary" max={5000}  anchorOrigin={{ vertical: 'top', horizontal: 'right', }}>
              <Typography variant={variant} color="text.secondary">
                {icon}
              </Typography>
            </Badge>
        ) ;

  }

  /*
  * Utility function to render a single summary item takes in 3 parameters
  * summary - the JSON representing the Summary item - could be weekly or annual doesnt matter
  * item - Primary used for displaying week number in the beginning of the row, Can be null so nothing will be displayed
  * target - The target for individual user - used to determine the color in which the total is displayed.
  */
  const RenderSummaryItem = ({summary, item, target}) => {
    var color = summary.Total >= target ? "success" : summary.Total >= target/2 ? "warning" : "error" ;
    var avatar = (item!= null)?<Avatar sx={{ bgcolor: blueGrey[500], width: 24, height: 24 }}>{item}</Avatar> : <></> 
    return (
      <Grid container sx={{border:'0px dotted black'}} >
        <Grid item xs={10}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  {avatar}
                  <RenderActivityDetailsChipInStack Summary={summary}/>
          </Stack>
        </Grid>
        <Grid item xs={2} alignItems="flex-end" justifyContent="flex-end">
               <Chip label={summary.Total} color={color} variant="filled" sx={{alignContent:'flex-end'}} />
        </Grid>
      </Grid>

    );
  }


  const RenderAthleteDetails = ({item, target}) => {
    return(
      <Grid container sx={{border:'0px dotted black'}} >
        <Grid item xs={2}>
              <Avatar src={item.picture}></Avatar>
        </Grid>
        <Grid item xs={8}>
            <Typography variant="subtitle1"> {item.name} </Typography>
        </Grid>
        <Grid item xs={2} alignItems="flex-end" justifyContent="flex-end">
                <BadgeWithPoints icon="🏁" variant="h5" points = {target} />
        </Grid>
      </Grid>

    );

  }

  export const RenderSummaryItemForDashboard= ({item,type}) => {
    //console.log(GetWeekNumber());
    var targetpoints = type === "Weekly" ? item.prefs.targetpoints : item.prefs.targetpoints*(GetWeekNumber()-1)
    var color = item.Summary.Total >= targetpoints ? "success" : item.Summary.Total>=targetpoints/2 ? "warning": "error" ;

    return(
    <Card sx={{border:'0px dotted black', p:0.5}}>
      <CardHeader  sx={{border:'0px dotted black', p:0.5}}
        avatar={
          <Avatar aria-label="recipe" src={item.picture}>
            R
          </Avatar>
        }
        title={item.name}
        subheader = {"🏁" + targetpoints }  
      />
        <Grid container sx={{border:'0px black' }}>
        <Grid item xs={10}sx={{border:'0px dotted black', display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
          <RenderActivityDetailsChipInStack Summary={item.Summary}/>
        </Grid>
        <Grid item xs={2} sx={{border:'0px dotted black',display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Chip size="small" label={item.Summary.Total} color={color} variant="filled" sx={{alignContent:'flex-end'}} />
        </Grid>
        </Grid>
    </Card>
    );
  }

  const RenderActivityDetailsBadgeInStack = ({Summary}) => {
           return(
            <Stack direction="row" justifyContent="flex-start" spacing={4}>
                    <BadgeWithPoints icon="🏃" variant="h6" points = {Summary.Run} />
                    <BadgeWithPoints icon="🚶"variant="h6" points = {Summary.Walk} />
                    <BadgeWithPoints icon="🚴" variant="h6" points = {Summary.Ride} />
                    <BadgeWithPoints icon="🏊" variant="h6" points = {Summary.Swim} />
                    <BadgeWithPoints icon="🤸" variant="h6" points = {Summary.Others} />
            </Stack>
           );
  }
  
  const RenderActivityDetailsChipInStack = ({Summary}) => {
           return(
            <Stack direction="row" justifyContent="flex-start" spacing={1}>
              {Summary.Run > 0 ?<Chip size="small" icon={<DirectionsRunOutlined/>} label= {Summary.Run} /> : <></>}
              {Summary.Walk > 0? <Chip size="small" icon={<DirectionsWalkOutlined/>} label = {Summary.Walk} /> : <></>}
              {Summary.Ride >0 ?<Chip size="small" icon={<PedalBikeOutlined/>} label = {Summary.Ride} /> :<></> }
              {Summary.Swim >0 ? <Chip size="small" icon={<PoolOutlined/>} label = {Summary.Swim} /> : <></>}
              {Summary.Others>0 ?<Chip size="small" icon={<FitnessCenterOutlined/>} label = {Summary.Others} /> : <></> }
            </Stack>
           );
  }
  /*
  /*
  * Renders the weekly summary accordion for user. Iterates through the array and renders one row per week
  */
 export const RenderUserWeeklySummary = ({SummaryList, Target}) => {
    return(
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Weekly Summary</Typography>
            </AccordionSummary>
            <Divider/>
            <AccordionDetails sx={{p:2}}>
              <Stack spacing={1} justifyContent="flex-start" direction="column-reverse" alignItems="center">
              {SummaryList.map(w => <RenderSummaryItem  summary={w.Summary} key={w.Week} item={w.Week} target={Target}/>) }
              </Stack>
            </AccordionDetails>
          </Accordion>
    );

  }


  const RenderSummary =  ({Title , Summary, Target}) =>{
     return(
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <Typography>{Title}</Typography>
            </AccordionSummary>
            <Divider/>
            <AccordionDetails sx={{p:1}}>
              <Stack spacing={0.1} sx={{border:'0px solid  black'}} justifyContent="flex-start" direction="column-reverse" alignItems="center">
                <RenderSummaryItem summary = {Summary}  key={Summary.Total} target= {Target}/>
              </Stack>
            </AccordionDetails>
          </Accordion>
    );
   
  }
  /*
  * Renders the annual summary accordion for user. The yearly target is determined by multiplying the target by number of weeks passed from the weekly array.
  */
export  const RenderUserAnnualSummary = ({Summary, Target}) => {
    return(

            <RenderSummary Title="Annual Summary" Summary={Summary} Target={Target} ></RenderSummary>
    );

  }

  /*
  * Renders the user preferences
  * TODO: Add functionality to update preferences and save them.
  */

  export  const RenderPreferences = ( {id,prefs}) => {
    const vertical='top';
    const horizontal='center';
    const [p, setP] = useState(prefs);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const handleClick = () => {
      setOpen(true);
      setLoading(false);
    };
  
    const handleClose = () => {
      setOpen(false);
      setLoading(false);
    };

    const handleSubmit = async (evt) => {
        setLoading(true);
        evt.preventDefault();
        await savePreferences(id, p).
        then (res => handleClick());
        
    }
    const handleChange = (event,prop) => {
      // If any of the boxes are checked we also need to check the active button to true
      if (event.target.checked)
        setP({...p,[prop]:event.target.checked, active:true});
      else
        setP({...p,[prop]:event.target.checked});
    };
    /*
    const handleTextChange = (event,prop) => {
      setP({...p,[prop]:event.target.value});
    };
    */
    const handleChangePoints = (event) => {
      console.log(event.target.value);
      setP({...p,targetpoints:event.target.value});
    };
   // const marks = [50,75,100,125,150,175,200,225,250,275,300].map(x =>{ value:x});
    
    return (
      <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{vertical:'top',horizontal:'center'}}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Preferences Saved
        </Alert>
      </Snackbar>
      <Snackbar open={loading} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical:'top',horizontal:'center'}}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Saving Preferences!!!
        </Alert>
      </Snackbar>
      
      <form onSubmit={handleSubmit}>
        <Paper sx={{ p: 5 }}>
          <Stack spacing={4} justifyContent="flex-start" direction="column-reverse" alignItems="center">
            <FormGroup sx={{ width: '90%' }}>
              <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, 'active')} checked={p.active} />} label="Active" />
              <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, 'springchallenge')} checked={p.springchallenge} />} label="Spring Challenge" />
              <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, 'summerchallenge')} checked={p.summerchallenge} />} label="Summer Challenge" />
              <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, 'autumnchallenge')} checked={p.autumnchallenge} />} label="Autumn Challenge" />
              <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, 'winterchallenge')} checked={p.winterchallenge} />} label="Winter Challenge" />
              <Typography variant="h6" component="div" gutterBottom> Weekly Target  </Typography>
              <Typography variant="h1" component="div" gutterBottom> </Typography>
              
              <FormControlLabel control={
              <Slider
                value={p.targetpoints}
                onChange={handleChangePoints}
                min={50}
                step={25}
                marks={true}
                max={300}
                valueLabelDisplay="on"
                aria-labelledby="non-linear-slider"
              />} label="" />
              
              <Button xs={12} lg={4} xl={2} variant="contained" type="submit">Save Preferences</Button>
            </FormGroup>
          </Stack>
        </Paper>
      </form>
      </>
    );
  }

export const RenderSeasonalSummaryRow =({item}) => {
  //console.log(item);
  return (
    <Card sx={{border:'0px dotted black', p:0.5}}>
      <CardHeader  sx={{border:'0px dotted black', p:0.5}}
        avatar={
          <Avatar aria-label="recipe" src={item.picture}>
            R
          </Avatar>
        }
        title={item.name}
        subheader = {"🏁" + item.prefs.targetpoints }  
      />
          <RenderWeeklyScoresForDashboard items={item.Summary} target={item.prefs.targetpoints} total={item.Total} totaltarget={item.totaltarget}/>
    </Card>
  );
}
/*
export const RenderActivity =({item}) => {
  return (
    <Card sx={{border:'0px dotted black', p:0.5}}>
      <CardHeader  sx={{border:'0px dotted black', p:0.5}}
        avatar={
          <Avatar aria-label="recipe" src={item.pic}>
            R
          </Avatar>
        }
        title={item.name}
        subheader = { item.Type}
      />
      <Grid sx={{display:'flex', border:'0px dashed grey'}}  container>
        <Grid sx={{display:'flex', alignItems:'center' ,border:'0px dashed grey'}} xs={10} item container>
          {item.Title}
          {item.Duration}
          {item.Distance}
          {item.Date}
        </Grid>
        <Grid sx={{display:'flex', justifyContent:'flex-end', border:'0px dashed grey'}} xs={2} item container>
        </Grid>
      </Grid> 
    </Card>
  );
}*/

export const RenderValueInCircle = ({value, label}) => {
  return  (
    <Typography variant="subtitle2">
      {label}  {value}
    </Typography>
  );

}

export const RenderActivity = ({item}) => {
  return (
  <Card variant="outlined" sx={{  border: '0px dotted gray' }}>
          <Stack direction="row">
            <CardHeader  sx={{width:'60%', border:'0px dotted black', p:0.5}}
              avatar={ <Avatar aria-label="recipe" src={item.pic}/> }
              title={limitString(item.name,18)}
              subheader = { item.Title}
            />
            <CardHeader  sx={{width:'40%', border:'0px dotted black', p:0.5}}
              title={getIconForType(item.Type)}
            />
          </Stack>
          <Stack spacing={1}  sx={{display:'flex'}}  divider={<Divider orientation="vertical" flexItem />} direction="row">
          <RenderValueInCircle value={item.Distance} label="📏"/>
          <RenderValueInCircle value={convertMinutesToTime(item.Duration)} label="⏲"/>
          <RenderValueInCircle value={item.Points} label="💯"/>
          <RenderValueInCircle value={item.Date} label="📅"/>
          </Stack>
          <Stack spacing={1}  sx={{display:'flex'}}  divider={<Divider orientation="vertical" flexItem />} direction="row">
          </Stack>
    </Card>
  );
}

const getIconForType = (type) => {
  switch (type) {
    case 'Run' :
      return <Chip icon={<DirectionsRunOutlined/>}/>;
    case 'Ride' :
      return <Chip icon={<PedalBikeOutlined/>}/>;
    case 'Walk' :
      return <Chip icon={<DirectionsWalkOutlined/>}/>;
    case 'Swim' :
      return <Chip icon={<PoolOutlined/>}/>;
    case 'Workout' :
      return <Chip icon={<FitnessCenterOutlined/>}/>;
    default :
      return <Chip icon={<FitnessCenterOutlined/>}/>;
  }
}

const RenderWeeklyScoresForDashboard = ({items,target, total, totaltarget }) => {
  console.log(totaltarget);
  var weeklychips= items.map (item => <Grid item xs={2}> <RenderTargetBasedChip value={item.Summary.Total} target={target} variant="outlined"  size="small"></RenderTargetBasedChip> </Grid>);
  //weeklychips = <Stack direction="row" justifyContent="flex-start" spacing={0.5}> {weeklychips} </Stack>
  //weeklychips = <Stack direction="row" justifyContent="flex-start" spacing={0.5}> {weeklychips} </Stack>
  var totalchip =  <RenderTargetBasedChip value={total} target={totaltarget} variant="filled" size="large"/>
//  totalchip = <Stack direction="row" justifyContent="flex-end" spacing={0.5}> {totalchip} </Stack>
  return(
  <Grid sx={{display:'flex', border:'0px dashed grey'}}  container>
    <Grid sx={{display:'flex', alignItems:'center' ,border:'0px dashed grey'}} xs={10} item container>
      {weeklychips}
    </Grid>
    <Grid sx={{display:'flex', justifyContent:'flex-end', border:'0px dashed grey'}} xs={2} item container>
      {totalchip}
    </Grid>
  </Grid>
  );
  return (<Stack direction="row"  spacing={0.5}>{weeklychips} {totalchip}</Stack>);
  
}

const RenderTargetBasedChip = ({value, target, variant, size} ) => {
  const color = (value>=target) ? "success" : (value >= target/2) ? "warning" : "error"; 
  return <Chip variant={variant} size={size} label={value} color={color}></Chip> 
}



export const RenderDashboardHeader = ({maintext, subtext}) => {
  return(
    <Grid container sx={{border:'0px solid blue', pb:0.1}} >
        <Grid item xs={12}>
            <Paper elevation={3} sx={{textAlign: 'center', width:'100%'}} >
              <Typography variant="h6" sx={{ bgcolor: 'text.primary', color: 'info.contrastText', p: 0.2 }}> 
                {maintext}
              </Typography>
              <Typography variant="subtitle1" sx={{ bgcolor: 'text.primary', color: 'info.contrastText', p: 0 }}> 
                {subtext}
              </Typography>
            </Paper>
        </Grid>
    </Grid>
  );
}


const SeasonalIcon = ( {season, active}) => {
  let icon = <QuestionMarkIcon/>;
  let color = active ? "success" : "error" ;
  switch (season) {
    case 'spring' : icon= <Badge color={color} variant="dot"> <SvgIcon> <Spring /> </SvgIcon> </Badge> 
    break ;
    case 'summer' : icon= <Badge color={color} variant="dot"> <SvgIcon > <Summer /> </SvgIcon> </Badge> 
    break ;
    case 'autumn' : icon= <Badge color={color} variant="dot"> <SvgIcon > <Autumn /> </SvgIcon> </Badge> 
    break ;
    case 'winter' :icon= <Badge color={color} variant="dot">  <SvgIcon > <Winter /> </SvgIcon> </Badge> 

    break ;
  }
  return icon;
}


export const RenderMember =({item}) => {
  const navigate=useNavigate();
  const ShowUserDashboard = (e, id) =>{
    navigate('/dashboard', {state : {id:id}})
  }
  return (
        <Paper>
      <Grid container sx={{border:'0px solid blue', m:0.5,p:0.5}} >
        <Grid item xs={2}>
                <Avatar aria-label="recipe" src={item.pic} onClick ={ (e) =>ShowUserDashboard(e,item.id)}/>
        </Grid>
        <Grid item xs={6}>
            <Stack >
              <Typography variant="subtitle1">{limitString(item.firstname + ' '+item.lastname, 15)}</Typography>
              <Typography variant="caption"> {"🏁" + item.prefs.targetpoints }</Typography>
            </Stack>
        </Grid>
        <Grid item xs={4}>
            <Stack sx={{p:0.5, pr:2, display:'flex', justifyContent:'flex-end'}}  direction="row" spacing={1} >
            <DirectionsRun fontSize="large" color={item.prefs.active?"success":"error"}/>
            <SeasonalIcon season='spring' active= {item.prefs.springchallenge}/>
            <SeasonalIcon season='summer' active= {item.prefs.summerchallenge}/>
            <SeasonalIcon season='autumn' active= {item.prefs.autumnchallenge}/>
            <SeasonalIcon season='winter' active= {item.prefs.winterchallenge}/>
            </Stack>
        </Grid>
      </Grid>
        </Paper>
  );
}

export const RenderUserActivities = ({activityList}) => {
  console.log(activityList);
     activityList.sort(reverseChronological);
     return(
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <Typography> Recent Activities </Typography>
            </AccordionSummary>
            <Divider/>
            <AccordionDetails sx={{p:1}}>
                <RenderActivityHeaderNarrow/>
              <Stack spacing={0.2} sx={{mt:0.5}} direction="column-reverse" alignItems="center">
                {activityList.map ( activity=> <RenderActivityNarrow activity = {activity} key={activity.id}/> )}
              </Stack>
            </AccordionDetails>
          </Accordion>
    );
}
/*
"Type": "Run",
        "Points": 37,
        "Title": "Evening run ",
        "Duration": "33.37",
        "id": 6482370128,
        "Week": 1,
        "Distance": "6.19",
        "Date": "2022-01-06"
*/
const RenderActivityNarrow = ({activity}) => {
  var stravalink = `https://www.strava.com/activities/${activity.id}`;
  return (
      <Paper sx={{width:"100%"}} elevation={1}>
  <Grid container columns={16} sx={{border:'0px dotted black'}} >
        <Grid item xs={4}>
          <Typography variant="body2">
            {activity.Date}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {activity.Week}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">
            {activity.Type}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {activity.Points}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {convertMinutesToTime(activity.Duration)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {activity.Distance}
          </Typography> 
        </Grid>
        <Grid item xs={1}>
          <Link href={stravalink} underline="hover" target='_blank' rel='noopener'>
            <img alt=    "stravalink" width="25" height="25" src={Strava_Logo}/> 
          </Link>
        </Grid>
      </Grid>
      </Paper>
  );

}

const RenderActivityHeaderNarrow = ({activity}) => {
  return (
    <Paper sx={{width:'100%', border:'0.1px solid black'}}>
  <Grid container columns={16} sx={{p:0.25,border:'0px dotted black'}} >
        <Grid item xs={4}>
          <Typography variant="body2">
            📅
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            🗓
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">
            Type
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            💯
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            ⏲
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            📏㎞
          </Typography> 
        </Grid>
      </Grid>
      </Paper>
  );

}
const convertMinutesToTime = (t) => {
  t=Math.floor(t);
  var hours = Math.floor(t/60);
  var mins = Math.floor(t%60);
  var paddedmins = mins.toString().padStart(2,0);
  return `${hours}:${paddedmins}`;
}

const reverseChronological = (a,b) => {
  return a.Date > b.Date? 1 : a.Date < b.Date ? -1 : 0
}
export const WaitForData = ({show}) => {
  return (
          <Backdrop
            sx={{ color: '#bbb', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={show}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
  );
}


export const RenderMembers = ( {members, color } ) => {
   return (<Paper sx={{p:3, border:'2px solid black'}}><Grid container spacing={1}>
      { members.map((user) =>  
                ( <Grid item xs={4} lg={3} >
                    <Chip
                        avatar={<Avatar src={user.pic} />} 
                        label={user.name}
                        color={color}
                        variant="filled"
                        size="small"
                      />  
                  </Grid>
                  ))  
        }   
   </Grid></Paper>);
}
