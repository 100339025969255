import React from 'react';
import {useState, useContext} from 'react';
import { BrowserRouter,Switch, Routes ,Navigate, Route, Redirect, Outlet } from 'react-router-dom';
import PrivateLayout from "./layouts/PrivateLayout";
import PublicLayout from "./layouts/PublicLayout";

import HomePage from "./pages/Home";
import AboutPage from "./pages/AboutPage";
import MembersPage from "./pages/MembersPage";
import AdminLogin from "./components/AdminLogin";

const localAuthState = (localStorage.getItem("user") == null) ? false:true
export const AuthContext = React.createContext();
const initialState = {
  isAuthenticated: (localStorage.getItem("user") == null) ? false:true ,
  user: localStorage.getItem("user"),
  pic: localStorage.getItem("pic"),
  token: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", action.payload.user);
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("pic", action.payload.pic);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        pic: action.payload.pic,
        token: action.payload.token
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};


function App() {

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [auth, setAuth] = useState(false);
  const [userid, setUserid] = useState("");

  function PrivateOutlet() {
    //console.log(state);
      return state.isAuthenticated ? <Outlet /> : <Navigate to="/" />;
    }

  const Logout = () => {
    dispatch({ type: "LOGOUT", payload: {user:"", token:"" } }) ;
    return <Navigate to="/" /> ;
  }
  console.log(state);
  return (
     <AuthContext.Provider value={{ state, dispatch }}>
      {!state.isAuthenticated ?
      <BrowserRouter>
          <Routes>
              <Route  path="/" element ={<PublicLayout> <HomePage/></PublicLayout>} > </Route>
              <Route path="/login" element = {<PublicLayout> <AdminLogin/></PublicLayout> } /> 
              <Route path="/about" element = {<PublicLayout> <AboutPage/></PublicLayout> } />
              <Route path="*" element = {<PublicLayout> <HomePage/></PublicLayout>} > </Route>
          </Routes>
      </BrowserRouter>
       :
      <BrowserRouter>
          <Routes>
              <Route  path="/" element ={<PrivateLayout> <HomePage/></PrivateLayout>} > </Route>
              <Route path="/members" element={<PrivateOutlet />}>
                <Route path="" element={<PrivateLayout> <MembersPage/> </PrivateLayout>} />
              </Route>
              <Route path="/logout" element = { <Logout/> } />
              <Route path="/about" element={<PrivateOutlet />}>
                <Route path="" element={<PrivateLayout> <AboutPage/> </PrivateLayout>} />
              </Route>
              <Route path="*" element = {<PrivateLayout> <HomePage/></PrivateLayout>} > </Route>
          </Routes>
      </BrowserRouter>
      }
    </AuthContext.Provider>
  );

}

export default App;
